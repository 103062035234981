/*------- Forms -------*/

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 0.9375rem;
	line-height: 1.6;
	color: #6b6f80 !important;
	height: auto;
	background: #f9f9f9;
	background-clip: padding-box;
	border: 1px solid rgba(107, 122, 144, 0.3);
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}
	&:focus {
		color: #6f6e6e;
		background-color: $white;
		outline: 0;
		box-shadow: none;
	}
	&::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
		color: #cbd2dc;
		opacity: 1;
	}
	&::-moz-placeholder {
		color: #cbd2dc;
	}
}
.form-group .form-control::-moz-placeholder {
	color: #cbd2dc;
}
.form-control {
	color: #99c1b2;
	opacity: 1;
	&:disabled, &[readonly] {
		background-color: #f9f9f9 !important;
		opacity: 1;
	}
}
select.form-control {
	&:not([size]):not([multiple]) {
		height: 2.375rem;
	}
	&:focus::-ms-value {
		color: #495057;
		background-color: $white;
	}
}
.form-control-file, .form-control-range {
	display: block;
	width: 100%;
}
.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	margin-bottom: 0;
	line-height: 1.6;
	color: #495057;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
	&.form-control-sm {
		padding-right: 0;
		padding-left: 0;
	}
}
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}
.form-control-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.14285714;
}
select.form-control-sm:not([size]):not([multiple]) {
	height: calc(1.8125rem + 2px);
}
.form-control-lg {
	padding: 0.5rem 1rem;
	font-size: 1.125rem;
	line-height: 1.44444444;
}
select.form-control-lg:not([size]):not([multiple]) {
	height: calc(2.6875rem + 2px);
}
.form-text {
	display: block;
	margin-top: 0.25rem;
}
.form-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
	> {
		.col, [class*="col-"] {
			padding-right: 5px;
			padding-left: 5px;
		}
	}
}
.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}
.form-check-input {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;
	&:disabled~.form-check-label {
		color: #505662;
	}
}
.form-check-label {
	margin-bottom: 0;
}
.form-check-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;
	.form-check-input {
		position: static;
		margin-top: 0;
		margin-right: 0.3125rem;
		margin-left: 0;
	}
}
.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
	border-color: $green;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
	border-color: $green;
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.was-validated .form-control:valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.form-control.is-valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated .custom-select:valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
	color: $green;
}
.was-validated .form-check-input:valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.form-check-input.is-valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated .custom-control-input:valid~.custom-control-label, .custom-control-input.is-valid~.custom-control-label {
	color: $green;
}
.was-validated .custom-control-input:valid~.custom-control-label::before, .custom-control-input.is-valid~.custom-control-label::before {
	background-color: #9eff3b;
}
.was-validated .custom-control-input:valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated .custom-control-input:valid:checked~.custom-control-label::before, .custom-control-input.is-valid:checked~.custom-control-label::before {
	background-color: #78ed00;
}
.was-validated .custom-control-input:valid:focus~.custom-control-label::before, .custom-control-input.is-valid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.was-validated .custom-file-input:valid~.custom-file-label, .custom-file-input.is-valid~.custom-file-label {
	border-color: $green;
}
.was-validated .custom-file-input:valid~.custom-file-label::before, .custom-file-input.is-valid~.custom-file-label::before {
	border-color: inherit;
}
.was-validated .custom-file-input:valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated .custom-file-input:valid:focus~.custom-file-label, .custom-file-input.is-valid:focus~.custom-file-label {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
	border-color: $danger;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
	border-color: $danger;
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}
.was-validated .form-control:invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.form-control.is-invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated .custom-select:invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.custom-select.is-invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated .form-check-input:invalid~.form-check-label, .form-check-input.is-invalid~.form-check-label {
	color: $danger;
}
.was-validated .form-check-input:invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.form-check-input.is-invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated .custom-control-input:invalid~.custom-control-label, .custom-control-input.is-invalid~.custom-control-label {
	color: $danger;
}
.was-validated .custom-control-input:invalid~.custom-control-label::before, .custom-control-input.is-invalid~.custom-control-label::before {
	background-color: #ec8080;
}
.was-validated .custom-control-input:invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before, .custom-control-input.is-invalid:checked~.custom-control-label::before {
	background-color: #e23e3d;
}
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before, .custom-control-input.is-invalid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 2px rgba(205, 32, 31, 0.25);
}
.was-validated .custom-file-input:invalid~.custom-file-label, .custom-file-input.is-invalid~.custom-file-label {
	border-color: $danger;
}
.was-validated .custom-file-input:invalid~.custom-file-label::before, .custom-file-input.is-invalid~.custom-file-label::before {
	border-color: inherit;
}
.was-validated .custom-file-input:invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated .custom-file-input:invalid:focus~.custom-file-label, .custom-file-input.is-invalid:focus~.custom-file-label {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}
.form-inline {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 20px !important;
	.form-check {
		width: 100%;
	}
}
@media (min-width: 576px) {
	.form-inline {
		label {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
			margin-bottom: 0;
		}
		.form-group {
			display: -ms-flexbox;
			display: flex;
			-ms-flex: 0 0 auto;
			flex: 0 0 auto;
			-ms-flex-flow: row wrap;
			flex-flow: row wrap;
			-ms-flex-align: center;
			align-items: center;
			margin-bottom: 0;
		}
		.form-control {
			display: inline-block;
			width: auto;
			vertical-align: middle;
		}
		.form-control-plaintext {
			display: inline-block;
		}
		.input-group, .custom-select {
			width: auto;
		}
		.form-check {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
			width: auto;
			padding-left: 0;
		}
		.form-check-input {
			position: relative;
			margin-top: 0;
			margin-right: 0.25rem;
			margin-left: 0;
		}
		.custom-control {
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
		.custom-control-label {
			margin-bottom: 0;
		}
	}
}
.form-control.header-search {
	border: 1px solid rgba(107, 122, 144, 0.3) !important;
	width: 100%;
	&::placeholder {
		color: #c7c5ca;
	}
	&:hover, &:focus {
		border-bottom: 1px solid rgba(107, 122, 144, 0.3) !important;
		border: 0;
		box-shadow: none;
	}
}
.form-fieldset {
	background: #f8f9fa;
	border: 1px solid #e9ecef;
	padding: 1rem;
	margin-bottom: 1rem;
}
.form-required {
	color: $danger;
	&:before {
		content: ' ';
	}
}
.state-valid {
	padding-right: 2rem;
	background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%235eba00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>") no-repeat center right 0.5rem / 1rem;
}
.state-invalid {
	padding-right: 2rem;
	background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23cd201f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>") no-repeat center right 0.5rem / 1rem;
}
.form-help {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	text-align: center;
	line-height: 1rem;
	color: $white;
	background: #7e6ee6;
	border-radius: 50%;
	font-size: 0.75rem;
	transition: .3s background-color, .3s color;
	text-decoration: none;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&:hover, &[aria-describedby] {
		background: #7e6ee6;
		color: $white;
	}
}
.form-group {
	display: block;
	margin-bottom: 1rem;
}
.form-label {
	display: block;
	margin-bottom: .375rem;
	font-weight: 600;
	font-size: 0.875rem;
}
.form-label-small {
	float: right;
	font-weight: 400;
	font-size: 87.5%;
}
.form-footer {
	margin-top: 2rem;
}
.wizard-card {
	.moving-tab {
		margin-top: 5px;
	}
	&.form-group .form-control {
		background-image: linear-gradient(#c4c4c4, #c4c4c4, linear-gradient(#e1e1e2, #e1e1e2));
	}
}
.wizard-container .wizard-navigation {
	background: #f9f9f9;
}
.form-inline .form-control::-webkit-input-placeholder {
	color: #cbd2dc;
}
.form-elements .custom-control-label {
	vertical-align: bottom !important;
}
@media (min-width: 1024px) {
	.form-inline .form-control {
		width: 180px !important;
	}
}

textarea:focus, .btn:focus, button:focus {
	outline: 0px !important;
	-webkit-appearance: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.fileinput-button {
	background: none repeat scroll 0 0 #eeeeee;
	border: 1px solid #e6e6e6;
	float: left;
	margin-right: 4px;
	overflow: hidden;
	position: relative;
}
.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 87.5%;
	color: $green;
}
.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 87.5%;
	color: $danger;
}
input {
	&[type="submit"].btn-block, &[type="reset"].btn-block, &[type="button"].btn-block {
		width: 100%;
	}
}
.fileinput-button input {
	cursor: pointer;
	direction: ltr;
	font-size: 23px;
	margin: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(-300px, 0px) scale(4);
}
.fileupload-buttonbar {
	.btn, .toggle {
		margin-bottom: 5px;
	}
}
.files .progress {
	width: 200px;
}
.fileupload-processing .fileupload-loading {
	display: block;
}
* {
	html .fileinput-button {
		line-height: 24px;
		margin: 1px -3px 0 0;
	}
	+html .fileinput-button {
		margin: 1px 0 0;
		padding: 2px 15px;
	}
}
@media (max-width: 767px) {
	.files {
		.btn span {
			display: none;
		}
		.preview * {
			width: 40px;
		}
		.name * {
			display: inline-block;
			width: 80px;
			word-wrap: break-word;
		}
		.progress {
			width: 20px;
		}
		.delete {
			width: 60px;
		}
	}
}
.richText {
	.richText-toolbar ul li a {
		border-right: rgba(0, 0, 0, 0.07) solid 1px;
		color: #646367;
	}
	border: rgba(0, 0, 0, 0.07) solid 1px;
	background-color: #f9f9f9;
}
.addui-slider .addui-slider-track .addui-slider-handle {
	&:hover {
		box-shadow: 0 0 5px rgba(159, 120, 255, 0.5);
		.addui-slider-value {
			background: rgba(159, 120, 255, 0.5);
		}
	}
	&.addui-slider-handle-active {
		background: rgba(159, 120, 255, 0.5);
		box-shadow: 0 0 5px rgba(159, 120, 255, 0.5);
		.addui-slider-value {
			background: rgba(159, 120, 255, 0.75);
		}
	}
}
.search {
	position: absolute;
	width: 320px;
	svg {
		transform: translateX(-126px);
		width: 180px;
		height: auto;
		stroke-width: 8px;
		stroke: $white;
		stroke-width: 1px;
		stroke-dashoffset: 0;
		stroke-dasharray: 64.6 206.305;
		transition: all 0.5s ease-in-out;
		margin-top: 5px;
	}
}
.input-search {
	position: absolute;
	width: calc(100% - 148px);
	height: 34px;
	top: 0;
	right: 20px;
	bottom: 0;
	left: 0;
	border: none;
	background-color: transparent;
	outline: none;
	padding: 20px;
	font-size: 16px;
	color: $white;
}
.search-label {
	position: absolute;
	display: block;
	width: 50px;
	height: 50px;
	top: 0;
	left: 50%;
	margin-left: -54px;
	z-index: 100;
	transition: 0.5s ease-in-out;
}
#form .list-group-item:hover {
	background: none;
}
#smartwizard {
	.nav.nav-tabs.step-anchor {
		border-bottom: 0 !important;
		padding: 30px;
	}
	.nav-tabs {
		border-top: 0 !important;
	}
	.nav-link {
		padding: 15px 40px;
	}
}
#form .list-group-item + .list-group-item {
	border-top-width: 1px;
}
  
/*------- Forms -------*/