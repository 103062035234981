/*------------------------------------------------------------------
[Master Stylesheet]

Project        :   Sparic Bootstrap Admin Template
Version        :   V.1
Create Date    :   20/January/2021
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/spruko
Support        :   sprukotechnolgies@gmail.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/
@import "variables";

/*
1.   Bootstrap-styles
2.   Accordion
3.   Alerts
4.   Avatars
5.   Badges
6.   Breadcrumb
7.   Button
8.   Cards
9.   Carousel
10.  Dropdown
11.  Forms
12.  Grid
13.  Inputgroup
14.  Jumbotron
15.  Modal
16.  Navigation
17.  Pagination
18.  Panel
19.  Popover
20.  Pricing
21.  Progress
22.  Table
23.  Tags
24.  Thumbnail
25.  Tooltip
26.  Components-col
27.  Countdown
28.  Custom-range
29.  Custom-range
30.  Custom-styles
31.  Feather-icons
32.  Gallery
33.  Highlight
34.  Label
35.  List-icons
36.  Lists
37.  Loader
38.  Navbar
39.  Product
40.  Rating
41.  Tabs-menu
42.  Timeline
43.  Footer
44.  Header
45.  Horizontalmenu
46.  Item-cards
47.  Media
48.  Sidebar
49.  Datepicker
50.  Jvector
51.  Select2
52.  Select-group
53.  Selectize
54.  Calender
55.  Charts
56.  Chat
57.  Errorpages
58.  Range-slider
59.  Ribbon
60.  Widgets
61.  Alignments
62.  Background
63.  Border
64.  Display
65.  Float-elements
66.  Height
67.  Margin
68.  Padding
69.  Position
70.  Typography
71.  Width
*/

/* ######## FONT ######## */
@import "custom/Fonts/fonts";


/* ######## BOOTSTRAP ######## */ 
@import "bootstrap/bootstrap-styles";
@import "bootstrap/accordion";
@import "bootstrap/alerts";
@import "bootstrap/avatars";
@import "bootstrap/badges";
@import "bootstrap/breadcrumb";
@import "bootstrap/button";
@import "bootstrap/cards";
@import "bootstrap/carousel";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/grid";
@import "bootstrap/inputgroup";
@import "bootstrap/jumbotron";
@import "bootstrap/modal";
@import "bootstrap/navigation";
@import "bootstrap/pagination";
@import "bootstrap/panel";
@import "bootstrap/popover";
@import "bootstrap/pricing";
@import "bootstrap/progress";
@import "bootstrap/table";
@import "bootstrap/tags";
@import "bootstrap/thumbnail";
@import "bootstrap/tooltip";

/*  ######## CUSTOM-STYLES ######## */ 
@import "custom/components-col";
@import "custom/countdown";
@import "custom/custom-range";
@import "custom/custom-range";
@import "custom/custom-styles";
@import "custom/icons";
@import "custom/gallery";
@import "custom/highlight";
@import "custom/label";
@import "custom/list-icons";
@import "custom/lists";
@import "custom/loader";
@import "custom/navbar";
@import "custom/product";
@import "custom/rating";
@import "custom/tabs-menu";
@import "custom/timeline";

/* ######## LAYOUT-STYLES ######## */
@import "layout/footer";
@import "layout/header";
@import "layout/horizontalmenu";
@import "layout/item-cards";
@import "layout/media";
@import "layout/sidebar";

/* ######## LIB-STYLES ######## */
@import "lib/datepicker";
@import "lib/jvector";
@import "lib/select2";
@import "lib/select-group";
@import "lib/selectize";

/* ######## TEMP-STYLES ######## */
@import "template/calender";
@import "template/charts";
@import "template/chat";
@import "template/errorpages";
@import "template/range-slider";
@import "template/ribbon";
@import "template/widgets";
@import "template/email";

/* ######## UTILITIES-STYLES ######## */
@import "utilities/alignments";
@import "utilities/background";
@import "utilities/border";
@import "utilities/display";
@import "utilities/float-elements";
@import "utilities/height";
@import "utilities/margin";
@import "utilities/padding";
@import "utilities/position";
@import "utilities/typography";
@import "utilities/width";
