/*------ panel -------*/

.expanel-default {
	border: #ddd !important;
}
.expanel-success {
	border: $green !important;
}
.expanel-danger {
	border: $danger !important;
}
.expanel-success>.expanel-heading {
	color: $white !important;
	background-color: $green !important;
	border-color: $green !important;
}
.expanel-danger>.expanel-heading {
	color: $white !important;
	background-color: $danger !important;
	border-color: $danger !important;
}
.expanel-warning>.expanel-heading {
	color: $white !important;
	background-color: $yellow !important;
	border-color: $yellow !important;
}
.expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
}
.expanel {
	margin-bottom: 20px !important;
	background-color: $white !important;
	border: 1px solid rgba(107, 122, 144, 0.3) !important;
	-webkit-box-shadow: 0 1px 1px $black-05 !important;
	box-shadow: 0 1px 1px $black-05 !important;
}
.expanel-default>.expanel-heading {
	background-color: #f9f9f9 !important;
	border-color: 1px solid rgba(107, 122, 144, 0.3) !important;
}
.expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid rgba(107, 122, 144, 0.3);
}
.expanel-footer {
	padding: 10px 15px !important;
	background-color: #f9f9f9 !important;
	border-top: 1px solid rgba(107, 122, 144, 0.3) !important;
}
.expanel-body {
	padding: 15px !important;
}
.panel.price {
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	>.panel-heading {
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		-webkit-transition: all .3s ease;
	}
	h3 {
		margin-bottom: 0;
		padding: 20px 0;
	}
}
.panel-heading {
	padding: 10px 15px;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	background: $white;
}
.panel.price {
	.list-group-item {
		&:last-child {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&:first-child {
			border-top-right-radius: 0px;
			border-top-left-radius: 0px;
		}
	}
	margin-bottom: 1.5rem;
	box-shadow: 0 0 30px 0 rgba(115, 77, 191, 0.2);
}
.panel-body {
	padding: 15px;
	.lead {
		strong {
			font-size: 40px;
			margin-bottom: 0;
		}
		font-size: 20px;
		margin-bottom: 0;
		padding: 10px 0;
	}
}
.panel-footer {
	padding: 10px 15px;
	background-color: #f7f7f7;
	border-top: 1px solid rgba(107, 122, 144, 0.3);
}
.panel.price .btn {
	box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
	border: 0px;
}
.panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: rgba(107, 122, 144, 0.3);
}
.panel-default>.panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: #f9f9f9;
	border: 1px solid rgba(107, 122, 144, 0.3);
}
.panel-title {
	font-size: 14px;
	margin-bottom: 0;
	>a {
		display: block;
		padding: 15px;
		text-decoration: none;
	}
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 1px solid rgba(107, 122, 144, 0.3);
}
.panel>.list-group {
	margin-bottom: 0;
	.list-group-item:first-child {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
}
.panel-heading+.list-group .list-group-item:first-child {
	border-top-width: 0;
}
.panel-default .list-group-item.active {
	color: $default-color;
	background-color: #f9f9f9;
	border-color: rgba(107, 122, 144, 0.3);
}
.panel-success .list-group-item.active {
	color: $white;
	background-color: $green;
	border-color: $green;
	&:hover {
		color: $white;
		background-color: #4b9400;
		border-color: #4b9400;
	}
}
.panel-info .list-group-item.active {
	color: $white;
	background-color: $azure;
	border-color: $azure;
	&:hover {
		color: $white;
		background-color: $info;
		border-color: $info;
	}
}
.panel-warning .list-group-item.active {
	color: $white;
	background-color: $yellow;
	border-color: $yellow;
	&:hover {
		color: $white;
		background-color: #cea70c;
		border-color: #cea70c;
	}
}
.panel-danger .list-group-item.active {
	color: $white;
	background-color: $danger;
	border-color: $danger;
	&:hover {
		color: $white;
		background-color: #d6434c;
		border-color: #d6434c;
	}
}
.panel-default .list-group-item.active {
	&:hover, &:focus {
		color: $default-color;
		background-color: #f9f9f9;
		border-color: #f9f9f9;
	}
}
.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: none;
	box-shadow: none;
	&:last-child {
		border-bottom: none;
	}
}
.panel-group1 {
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}
	.panel1 {
		border-radius: 0;
	}
}
.panel-body1 {
	padding: 10px;
	background: $white;
}
.panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
}
.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}
.panel-heading1 {
	background-color: #5797fc;
	border-radius: 0;
	border: none;
	color: $white;
	padding: 0;
}
.panel-group1 .panel-body {
	border: 1px solid rgba(107, 122, 144, 0.3);
}
.panel-title1 a {
	display: block;
	color: $white;
	padding: 10px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
}
.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}
	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius 0.3s linear 0.2s;
		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}
.panel-title a.accordion-toggle {
	&:before {
		content: "\f068";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
	&.collapsed:before {
		content: "\f067";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
}
.panel-heading1 {
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 7px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 7px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all 0.5s;
		transform: scale(0);
	}
}
.expanel.expanel-success .table th {
	border-top: 0 !important;
}
.panel-title1 .accordion-toggle{
    color: $white;
}
/*------ panel -------*/