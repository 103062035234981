/*-----Alerts-----*/

.alert {
	position: relative;
	padding: 0.4rem 1rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 3px;
	font-size: 0.9375rem;
	&:first-child {
		margin-bottom: 1rem !important;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.alert-heading {
	color: inherit;
}
.alert-link {
	font-weight: 500;
	&:hover, a:hover {
		text-decoration: underline;
	}
}
.alert-dismissible {
	padding-right: 3.90625rem;
	.close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.75rem 1.25rem;
		color: inherit;
	}
}
.alert-primary {
	color: #464a4e;
	background-color: #c5b8e6;
	border-color: #d1bffd;
	hr {
		border-top-color: #b7cded;
	}
	.alert-link {
		color: #172b46;
	}
}
.alert-default {
	color: #24214c;
	border-color: rgba(241, 240, 245, 0.9);
	background-color: rgba(241, 240, 245, 0.7);
}
.alert-secondary {
	color: #464a4e;
	background-color: #dadce6;
	border-color: #dddfe2;
	hr {
		border-top-color: #cfd2d6;
	}
	.alert-link {
		color: #2e3133;
	}
}
.alert-success {
	color: #316100;
	background-color: #a9dea3;
	border-color: #99d292;
	hr {
		border-top-color: #c5e7a4;
	}
	.alert-link {
		color: #172e00;
	}
}
.alert-info {
	color: #24587e;
	background-color: #9eecff;
	border-color: #6cd5ef;
	hr {
		border-top-color: #b3dcf9;
	}
	.alert-link {
		color: #193c56;
	}
}
.alert-warning {
	color: #7d6608;
	background-color: #ffdd81;
	border-color: #e6bb45;
	hr {
		border-top-color: #fae8a4;
	}
	.alert-link {
		color: #4d3f05;
	}
}
.alert-danger {
	color: #6b1110;
	background-color: #fb959b;
	border-color: #f7727a;
	hr {
		border-top-color: #ecacab;
	}
	.alert-link {
		color: #3f0a09;
	}
}
.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;
	hr {
		border-top-color: #ececf6;
	}
	.alert-link {
		color: #686868;
	}
}
.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
	hr {
		border-top-color: #b9bbbe;
	}
	.alert-link {
		color: #040505;
	}
}
.alert-icon {
	padding-left: 3rem;
	>i {
		color: inherit !important;
		font-size: 1rem;
		position: absolute;
		top: 1rem;
		left: 1rem;
	}
}
.alert-avatar {
	padding-left: 3.75rem;
	.avatar {
		position: absolute;
		top: .5rem;
		left: .75rem;
	}
}
/*-----Alerts-----*/