/*------ Card -------*/

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: $white;
	background-clip: border-box;
	border: 1px solid rgba(107, 122, 144, 0.3);
	border-radius: 4px;
	margin-bottom: 20px;
	width: 100%;
	> {
		hr {
			margin-right: 0;
			margin-left: 0;
		}
		.list-group {
			&:first-child .list-group-item:first-child {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}
			&:last-child .list-group-item:last-child {
				border-bottom-right-radius: 7px;
				border-bottom-left-radius: 7px;
			}
		}
	}
}
.card-subtitle {
	margin-top: -0.75rem;
	margin-bottom: 0;
}
.card-text:last-child {
	margin-bottom: 0;
}
.card-link {
	&:hover {
		text-decoration: none;
	}
	+.card-link {
		margin-left: 1.5rem;
	}
}
.card-header+.list-group .list-group-item:first-child {
	border-top: 0;
}
.card-footer {
	padding: 1.5rem 1.5rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(107, 122, 144, 0.3);
	color: $default-color;
	&:last-child {
		border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
	}
}
.card-header-tabs {
	margin-right: -0.75rem;
	margin-bottom: -1.5rem;
	margin-left: -0.75rem;
	border-bottom: 0;
	margin: -1.25rem 0;
	line-height: 2rem;
}
.card-header-pills {
	margin-right: -0.75rem;
	margin-left: -0.75rem;
	margin: -.75rem 0;
}
.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
	background-color: $black-4;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.card-img {
	width: 100%;
	border-radius: calc(3px - 1px);
}
.card-img-top, .card-img-bottom {
	width: 100%;
}
.card-deck {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	.card {
		margin-bottom: 0.75rem;
	}
}
@media (min-width: 576px) {
	.card-deck {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		margin-right: -0.75rem;
		margin-left: -0.75rem;
		.card {
			display: -ms-flexbox;
			display: flex;
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-right: 0.75rem;
			margin-bottom: 0;
			margin-left: 0.75rem;
		}
	}
}
.card-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	>.card {
		margin-bottom: 0.75rem;
	}
}
@media (min-width: 576px) {
	.card-group {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		>.card {
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			margin-bottom: 0;
			+.card {
				margin-left: 0;
				border-left: 0;
			}
			&:first-child {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				.card-img-top, .card-header {
					border-top-right-radius: 0;
				}
				.card-img-bottom, .card-footer {
					border-bottom-right-radius: 0;
				}
			}
			&:last-child {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				.card-img-top, .card-header {
					border-top-left-radius: 0;
				}
				.card-img-bottom, .card-footer {
					border-bottom-left-radius: 0;
				}
			}
			&:only-child {
				border-radius: 3px;
				.card-img-top, .card-header {
					border-top-left-radius: 3px;
					border-top-right-radius: 3px;
				}
				.card-img-bottom, .card-footer {
					border-bottom-right-radius: 3px;
					border-bottom-left-radius: 3px;
				}
			}
			&:not(:first-child):not(:last-child):not(:only-child) {
				border-radius: 0;
				.card-img-top, .card-img-bottom, .card-header, .card-footer {
					border-radius: 0;
				}
			}
		}
	}
}
.card-columns .card {
	margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
		.card {
			display: inline-block;
			width: 100%;
		}
	}
}
.card-blog-overlay .card-header {
	border-bottom: 1px solid rgba(107, 122, 144, 0.3);
}
.card-blog-overlay1 .card-footer, .card-blog-overlay2 .card-footer {
	border-top: 1px solid $white-2;
}
.card-blog-overlay:before, .card-blog-overlay1:before, .card-blog-overlay2:before {
	content: '';
	display: block;
	position: absolute;
	background: $black-5;
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
}
.card-blog-overlay {
	background: url('../images/photos/thumb3.jpg');
	background-size: cover;
	border-radius: 7px;
}
.card-blog-overlay1 {
	background: url('../images/photos/thumb4.jpg');
	background-size: cover;
	border-radius: 7px;
}
.card-blog-overlay2 {
	background: url('../images/photos/22.jpg');
	background-size: cover;
	border-radius: 7px;
}
.card .card {
	box-shadow: none;
}
@media print {
	.card {
		box-shadow: none;
		border: none;
	}
}
.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0;
	padding: 20px;
	position: relative;
	+.card-body {
		border-top: 1px solid rgba(107, 122, 144, 0.3);
	}
	> :last-child {
		margin-bottom: 0;
	}
}
@media print {
	.card-body {
		padding: 0;
	}
}
.card-body-scrollable {
	overflow: auto;
}
.card-footer, .card-bottom {
	padding: 1rem 1.5rem;
	background: none;
}
.card-header {
	background: none;
	padding: 20px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	border-bottom: 0;
	&.border-0+.card-body {
		padding-top: 0;
	}
	.card-title {
		margin-bottom: 0;
	}
}
@media print {
	.card-header {
		display: none;
	}
}
.card-title {
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	margin-bottom: 5px;
	a {
		color: inherit;
	}
	&:only-child {
		margin-bottom: 0;
	}
	small {
		color: #757b88;
		font-size: 12px;
		display: block;
		margin: -.7rem 0 1rem;
		line-height: 1.1;
		font-weight: 400;
		margin-bottom: 0;
		text-transform: uppercase;
	}
}
.card-subtitle {
	color: #757b88;
	font-size: 12px;
	display: block;
	margin: -.7rem 0 1rem;
	line-height: 1.1;
	font-weight: 400;
	margin-bottom: 0;
	text-transform: uppercase;
}
.card-table {
	margin-bottom: 0;
	tr {
		td:first-child, th:first-child {
			padding-left: 1.5rem;
		}
		td:last-child, th:last-child {
			padding-right: 1.5rem;
		}
	}
}
.card-body+.card-table {
	border-top: 1px solid rgba(107, 122, 144, 0.3);
}
.card-profile .card-header {
	height: 5rem;
	background-size: cover;
	border: 0;
}
.profie-img .flex-md-row img {
	width: 60px;
}
.card-profile-img {
	max-width: 6rem;
	margin-bottom: 1rem;
	border: 3px solid $white;
	border-radius: 100%;
	box-shadow: 0 1px 1px $black-1;
}
.card-body+.card-list-group {
	border-top: 1px solid rgba(107, 122, 144, 0.3);
}
.card-list-group .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	&:last-child {
		border-bottom: 0;
	}
	&:first-child {
		border-top: 0;
	}
}
.card-header-tabs .nav-item {
	margin-bottom: 1px;
}
.card-aside {
	-ms-flex-direction: row;
	flex-direction: row;
}
.card-aside-column {
	min-width: 5rem;
	width: 20%;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	background: no-repeat center/cover;
}
.card-value {
	font-size: 1rem;
	line-height: 3.4rem;
	height: 3.4rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;
	i {
		vertical-align: middle;
	}
}
.card-chart-bg {
	height: 4rem;
	margin-top: -1rem;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
.card-options {
	margin-left: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-order: 100;
	order: 100;
	margin-right: -.5rem;
	color: #cbd2dc;
	-ms-flex-item-align: center;
	align-self: center;
	a:not(.btn) {
		&:hover {
			text-decoration: none;
			color: #6e7687;
		}
		i {
			font-size: 1rem;
			vertical-align: middle;
		}
	}
	.dropdown-toggle:after {
		display: none;
	}
}
.stretch-card {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: stretch;
	justify-content: stretch;
	>.card {
		width: 100%;
		min-width: 100%;
	}
}
.card-img-absolute {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
}
.card-collapsed {
	> :not(.card-header):not(.card-status) {
		display: none;
	}
	.card-options-collapse i:before {
		content: '\e92d';
	}
}
.card-fullscreen {
	.card-options-fullscreen i:before {
		content: '\e992';
	}
	.card-options-remove {
		display: none;
	}
}
.card-map {
	height: 15rem;
	background: #e9ecef;
}
.card-map-placeholder {
	background: no-repeat center;
}
.card-tabs {
	display: -ms-flexbox;
	display: flex;
}
.card-tabs-bottom .card-tabs-item {
	border: 0;
	&.active {
		border-top-color: $white;
	}
}
.card-tabs-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: block;
	padding: 1.4rem 1.5rem;
	color: inherit;
	overflow: hidden;
}
a.card-tabs-item {
	background: #fafbfc;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
	&:focus {
		z-index: 1;
	}
	&.active {
		background: $white;
		border-bottom-color: $white;
	}
}
.card-status {
	position: absolute;
	top: -1px;
	left: 0px;
	right: 0px;
	height: 3px;
	border-radius: 7px 7px 0 0;
	background: rgba(0, 40, 100, 0.12);
}
.card-status-left {
	right: auto;
	bottom: 0;
	height: auto;
	width: 3px;
	border-radius: 3px 0 0 3px;
}
.card-icon {
	width: 3rem;
	font-size: 2.5rem;
	line-height: 3rem;
	text-align: center;
}
.card-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
}
.card-alert {
	border-radius: 0;
	margin: -1px -1px 0;
}
.card-category {
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	background: #f9f9f9;
}
.card-counter i {
	font-size: 5em;
}
.card-blog-overlay6 {
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(96, 82, 159, 0.8);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	background: url(../images/media/media5.jpg);
	background-size: cover;
	i {
		font-size: 3rem;
	}
}
.card-counter2 i {
	font-size: 4.5em;
}
.card-profile {
	.nav-pills .nav-link {
		font-size: .875rem;
		font-weight: 500;
		padding: .75rem 1rem;
		transition: all .15s ease;
		border: 1px solid rgba(107, 122, 144, 0.3);
	}
	.avatar {
		font-size: 1rem;
		display: inline-flex;
		width: 48px;
		height: 48px;
		color: $white;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
	}
	.card {
		border: 1px solid rgba(107, 122, 144, 0.3) !important;
	}
	.nav-link {
		display: block !important;
		width: 90%;
	}
}
.card-pricing {
	z-index: 1;
	h1 {
		font-size: 2.5rem !important;
		padding: 15px 30px;
	}
}
.card-pricing2 h1, .card-pricing3 h1, .card-pricing4 h1 {
	font-size: 2.5rem !important;
	padding: 15px 30px;
}
.card-pricing2 {
	z-index: 1;
	border: 2px solid $blue;
}
.card-pricing3 {
	z-index: 1;
	border: 2px solid $warning;
}
.card-pricing4 {
	z-index: 1;
	border: 2px solid $success;
}
.card-pricing .list-unstyled li, .card-pricing2 .list-unstyled li, .card-pricing3 .list-unstyled li, .card-pricing4 .list-unstyled li {
	padding: .5rem 0;
	border-bottom: 0 !important;
}
.card-bgimg {
	background: url(../images/photos/bg.jpg) center center;
	background-size: cover;
	position: relative;
	border-radius: 7px;
	&:before {
		opacity: 0.9;
		border-radius: 7px;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		top: 0;
		z-index: 1;
		border-radius: 7px;
	}
	.card-body {
		z-index: 10;
		border-right: 1px solid $white-1;
	}
}
/*------ Card -------*/