/*------ Accordion -------*/

.accordion .card {
	&:not(:first-of-type) {
		&:not(:last-of-type) {
			border-bottom: 0;
			border-radius: 0;
		}
		.card-header:first-child {
			border-radius: 0;
		}
	}
	&:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all 0.5s;
	}
	&.active a:before {
		transform: rotate(0deg);
		transition: all 0.5s;
	}
}
.demo-accordion p:last-child, .panel-group1 p:last-child {
	margin-bottom: 0;
}
.accordion {
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
	.accordion-header, .accordion-body {
		padding: 10px 15px;
	}
	.accordion-header {
		background-color: #f0f0f5;
		border-radius: 3px;
		cursor: pointer;
		-webkit-transition: all .5s;
		-o-transition: all .5s;
		transition: all .5s;
		h4 {
			margin: 0;
			font-size: 14px;
		}
		&:hover {
			background-color: #f0f0f5;
		}
		&[aria-expanded="true"] {
			-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
			color: $white;
		}
	}
	.accordion-body {
		line-height: 24px;
	}
}
/* style-1--*/

.acc-1 .accordion-header, .accordion-body {
	background: $white;
}
.acc-1 {
	.accordion__item {
		margin-bottom: 3px;
		display: inline-block;
		width: 100%;
	}
	.accordion-header {
		padding: 10px 14px;
		background: #f9f9f9;
		text-transform: uppercase;
		cursor: pointer;
		font-size: 14px;
		transition: all .3s;
		border-radius: .25rem;
	}
	.active .accordion-header {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.accordion-header:hover {
		color: $white;
		position: relative;
		z-index: 5;
	}
	.accordion-body {
		background: $white;
		color: #3f3c3c;
		display: none;
	}
	.accordion-body__contents {
		padding: 10px 15px;
		font-size: 14px;
		border: 1px solid #e6eaea;
		background: $white;
	}
	.accordion__item.active:last-child .accordion-header {
		border-radius: none;
	}
	.accordion:first-child>.accordion__item>.accordion-header {
		border-bottom: 1px solid transparent;
	}
	.accordion__item {
		>.accordion-header:after {
			content: "\f107";
			font-family: IonIcons;
			font-size: 1.2em;
			float: right;
			position: relative;
			top: -2px;
			transition: .3s all;
			transform: rotate(0deg);
			font-family: 'FontAwesome' !important;
		}
		&.active {
			>.accordion-header:after {
				transform: rotate(-180deg);
				left: 3px;
				top: -3px;
			}
			.accordion-header {
				border-radius: .25rem;
			}
		}
	}
}
.accor h5 a {
	padding: .75rem 1.25rem;
	display: block;
	border-bottom: 1px solid #c7d5e2;
}
#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all 0.5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all 0.5s;
	}
}
/*------ Accordion -------*/
