/*--- product ---*/

.product-price {
	font-size: 1rem;
	strong {
		font-size: 1.5rem;
	}
}
.single-productslide .product-gallery .product-item {
	margin-bottom: 10px;
	overflow: hidden;
	background-color: $white;
}
.product-gallery {
	.product-item img {
		height: 350px;
		width: auto;
		display: inline-block;
		cursor: -webkit-zoom-in;
		cursor: zoom-in;
	}
	.product-sm-gallery {
		text-align: center;
		.item-gallery {
			img {
				max-width: 100%;
				max-height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				border-radius: 2px;
				cursor: -webkit-zoom-in;
				cursor: zoom-in;
			}
			width: 80px;
			height: 80px;
			border: 1px solid rgba(167, 180, 201, 0.3);
			padding: 5px;
			display: inline-block;
			overflow: hidden;
			line-height: 4.5;
		}
	}
}
.single-productslide .product-gallery-data {
	padding: 30px 0 0;
}
.product-gallery-data .product-gallery-data1 dt, dd {
	display: inline-block;
}
.product-gallery-data {
	dl {
		margin-bottom: 5px;
	}
	.product-gallery-data1 {
		dt {
			width: 100px;
			float: left;
		}
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
	}
}
.product-gallery-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	font-size: 18px;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}
.product-gallery-rats {
	margin-top: 20px;
}
.product-gallery-data {
	.label-rating {
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.product-gallery-quty dt {
		display: inline-block;
	}
}
dd, .product-gallery-data .product-gallery-size dt, dd {
	display: inline-block;
}
.product-gallery-data .product-gallery-size .product-gallery-checks label {
	display: inline-block;
	margin-left: 10px;
}
.product-card-img img {
	height: 180px;
	width: 180px;
}
.product-card-icons {
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	display: inline-block;
}
.product-card-footer .product-footer-wrap .footer-wrap-price {
	font-size: 16px;
	font-weight: 600;
	del {
		margin-left: 5px;
	}
}
.product-card4-footer .product-card4-wrap .product-card4-price h6, .product-card5-footer .product-card5-price h6 {
	font-size: 25px;
	font-weight: 600;
}
.product-card6-footer {
	.product-card6-price h6 {
		font-size: 25px;
		font-weight: 600;
	}
	.product-card6-info del {
		margin-right: 5px;
	}
}
.product-card7-footer .product-card7-price {
	h6 {
		font-size: 25px;
		font-weight: 600;
	}
	del, span {
		margin-left: 5px;
	}
}
.product-gallery-data .product-gallery-size dt, dd {
	display: flex;
}
.product-filter-desc .product-filter-icons a {
	width: 2.5em;
	height: 2.5em;
	line-height: 2.4em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
}
.product-img img {
	background: rgba(245, 242, 249, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.02);
}
/*--- product ---*/