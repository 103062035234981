/*------Drop Downs-------*/

.dropup, .dropright, .dropdown, .dropleft {
	position: relative;
}
.dropdown-toggle {
	&::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
		margin-left: 12px;
	}
	&:empty::after {
		margin-left: 0;
	}
}
.dropdown-menu-right {
	right: 0;
	left: auto;
}
.dropup {
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0;
			border-right: 0.3em solid transparent;
			border-bottom: 0.3em solid;
			border-left: 0.3em solid transparent;
		}
		&:empty::after {
			margin-left: 0;
		}
	}
}
.dropright {
	.dropdown-menu {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0;
			border-bottom: 0.3em solid transparent;
			border-left: 0.3em solid;
		}
		&:empty::after {
			margin-left: 0;
		}
	}
}
.dropleft {
	.dropdown-menu {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0.3em solid;
			border-bottom: 0.3em solid transparent;
		}
		&::before {
			display: none;
			width: 0;
			height: 0;
			margin-right: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0.3em solid;
			border-bottom: 0.3em solid transparent;
		}
		&:empty::after {
			margin-left: 0;
		}
	}
}
.dropdown-menu {
	&[x-placement^="top"], &[x-placement^="right"], &[x-placement^="bottom"], &[x-placement^="left"] {
		right: auto;
		bottom: auto;
	}
}
.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e4e4e4;
}
.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.drop-icon-wrap {
	text-align: center;
	li {
		display: block;
	}
	.drop-icon-item {
		display: inline-block;
		padding-bottom: 10px;
		padding-top: 10px;
		text-align: center;
		color: #020202;
		text-transform: capitalize;
		width: 50%;
		float: left;
	}
}
.drop-icon-item i {
	font-size: 20px;
	padding-bottom: 5px;
	color: #a7a8c6;
}
.drop-icon-wrap .drop-icon-item {
	&:hover, &:focus {
		text-decoration: none;
		background-color: #f9f9f9;
	}
}
.dropdown-item {
	&.user {
		font-size: 16px;
		padding: 5px;
	}
	&:hover, &:focus, &.active, &:active {
		text-decoration: none;
		background-color: #f9f9f9;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: transparent;
	}
}
.dropdown-menu.show {
	display: block;
	margin: 0;
	box-shadow: 0px 5px 25px $black-1;
	border: 0;
}
.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: $gray;
	white-space: nowrap;
}
.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}
.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
	&::after {
		margin-left: 0;
	}
}
.dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}
.dropdown {
	display: block;
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 9999;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	background-color: $white;
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
}
.dropdown-item {
	color: #020202;
}
.dropdown-menu-arrow.dropdown-menu-right {
	&:before, &:after {
		left: auto;
		right: 12px;
	}
}
.dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	&:after {
		vertical-align: 0.155em;
	}
	&:empty:after {
		margin-left: 0;
	}
}
.dropdown-icon {
	color: #757b88;
	margin-right: .5rem;
	margin-left: -.5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
}
ul.dropdown-menu li.dropdown ul.dropdown-menu {
	position: absolute !important;
	width: 100% !important;
}
.dropdown-menu {
	>li>a {
		display: block;
		padding: 0.5rem 1.5rem;
		clear: both;
		font-weight: 400;
		line-height: 1.428571429;
		color: #333;
		white-space: nowrap;
		&:hover, &:focus {
			text-decoration: none;
			background-color: #f9f9f9;
		}
	}
	.divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: rgba(107, 122, 144, 0.3);
	}
	.dropdown-plus-title {
		width: 100% !important;
		color: #6b6f80 !important;
		padding: 6px 12px !important;
		font-weight: 500 !important;
		border: 0 solid #d4cfe3 !important;
		border-bottom-width: 1px !important;
		cursor: pointer !important;
	}
}
ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	line-height: 20px !important;
}
.dropdown-menu-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 15px;
	border-bottom: 1px solid rgba(107, 122, 144, 0.3);
}
.dropdown-demo .dropdown-menu {
	position: static;
	float: none;
}
.dropdown-menu-header label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #6b6f80;
}
.dropdown-media-list {
	.media {
		&:hover, &:focus {
			background-color: #f8f9fa;
		}
		padding: 12px 15px;
	}
	img {
		border-radius: 100%;
	}
	.media-body {
		margin-left: 15px;
		> {
			div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				p {
					margin-bottom: 0;
					font-weight: 500;
					color: #6b6f80;
					font-size: 14px;
				}
				span {
					font-size: 12px;
				}
			}
			p {
				font-size: 14px;
				margin-bottom: 0;
			}
		}
	}
}
.dropdown-list-footer {
	padding: 10px 15px;
	text-align: center;
	font-size: 12px;
	border-top: 1px solid rgba(107, 122, 144, 0.3);
}
.dropdown-media-list {
	padding: 0;
}
@media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
		padding-top: 15px !important;
		padding-bottom: 15px !important;
	}
}
@media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu {
		width: auto !important;
	}
}
.drop-icon-wrap .drop-icon-item .drop-font {
	font-size: 12px;
}
/*------Drop Downs-------*/