/*----Pricing Tables----*/

.price {
	.list-group-item {
		border-bottom: 1px solid rgba(250, 250, 250, 0.5);
	}
	.panel-footer {
		border-bottom: 0px;
		background-color: $white;
	}
	&.panel-color>.panel-body {
		background-color: $white;
	}
}
.pt-inner {
	text-align: center;
	.pti-header {
		padding: 45px 10px 70px;
		color: $white;
		position: relative;
		border: 1px solid rgba(107, 122, 144, 0.3);
		border-bottom: 0;
		>h2 {
			margin: 0;
			line-height: 100%;
			font-weight: 100;
			font-size: 50px;
			color: $white;
			small {
				letter-spacing: 0;
				vertical-align: top;
				font-size: 16px;
				font-weight: 100;
			}
		}
		.ptih-title {
			background-color: $black-1;
			padding: 8px 10px 9px;
			margin: 0 -10px;
			position: absolute;
			width: 100%;
			bottom: 0;
			border-bottom: 0;
			text-transform: uppercase;
		}
	}
	.pti-body .ptib-item {
		&:not(:last-child) {
			border-bottom: 1px solid #eee;
		}
		padding: 15px 0;
		font-weight: 400;
	}
	.pti-footer {
		padding: 20px 0;
		border-top: 0 !important;
	}
}
/*----Pricing Tables----*/